<template>
  <section style="background: #FFFFFF">
    <!--组织展示-->
    <el-col :span="0">
      <div class="orgbtndivtree">
        <el-divider>选择部门</el-divider>
      </div>
      <div class="orgtree" style="padding-top:0px;height:100vh;width:auto;" >
        <!-- <el-tree
          :data="alldepartmentdata"
          show-checkbox
          :expand-on-click-node="false"
          :default-expand-all="false"
          :check-strictly="false"
          :check-on-click-node="true"
          highlight-current
          :indent="30"
          @check="handleCheck"
          node-key="id"
          ref="tree1"
        ></el-tree> -->
        <!-- <el-table :data="departusers"
                  highlight-current-row
                  :row-style="{height:'40px'}"
                  :cell-style="{padding:'0px'}"
                  :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                  @current-change="selectDepartUserRow">
          <el-table-column type="index"
                           width="70px"></el-table-column>
          <el-table-column label="姓名"
                           prop="UserNameClaim"
                           align="left"></el-table-column>
        </el-table>
        <el-pagination align='center' small
                       :pager-count="5"
                       @current-change="handleCurrentChangeDepart"
                       :current-page="DepartcurrentPage"
                       :page-size="DepartpageSize"
                        layout="prev, pager, next"
                       :page-count="Departtotal"
                       :total="Departtotaldata">
        </el-pagination> -->
      </div>
    </el-col>
    <!--工作数据展示-->
    <el-col :span="24">
      <div>
          <!-- <el-collapse :v-model="collActive" >
            <el-collapse-item title="开启/关闭筛选条件>>>" name="1"> -->

        <!-- <el-divider>工作任务</el-divider> -->
              <el-form :inline="true"
                      style="width: 100%;overflow-x: auto;white-space: nowrap;margin-top:5px" size="mini">
                <el-form-item style="margin-left:3px">
                  <el-button type="default" @click="handleChooseDpt">{{btntext}}</el-button>
                  <el-button type="default" icon="el-icon-user-solid" @click="handleChooseGroup">常用人员</el-button>
                </el-form-item>
                <el-form-item label="人员:">
                  <el-select size="mini" style="width:310px" v-model="myvalue"
                    filterable
                    multiple placeholder="输入姓名筛选">
                    <el-option
                      v-for="item in myoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="工作项目:">
                  <el-input v-model="workItem"
                            placeholder="输入项目"
                            maxlength="15"
                            show-word-limit
                            clearable
                            style="width: 152%;"></el-input>
                </el-form-item>
                <el-form-item label="工作类型:"
                              style="margin-left: 95px;">
                  <el-cascader  v-model="taskClassify"
                            style="width: 56%;"
                    :options="ClassifyModules"
                    :show-all-levels="false"
                    :props="{ checkStrictly: true }"
                    clearable></el-cascader>

                </el-form-item>
                <el-form-item label="任务进度:"
                              style="margin-left: -80px;">
                  <el-select v-model="progressWhere"
                            style="width: 30%;">
                    <el-option v-for="item in progressValueWhere"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"></el-option>
                  </el-select>
                  <el-input v-model="progressValue"
                            placeholder="请输入"
                            style="width:40%"
                            type="number"
                            min=0
                            max=100></el-input>
                </el-form-item>
              </el-form>
              <el-form :inline="true"
                      style="width: 100%;overflow-x: auto;white-space: nowrap;"
                      size="mini">
                <el-form-item label="任务状态:"
                              style="margin-left: 3px;">
                  <el-select v-model="taskStatus"
                            style="width: 50%;">
                    <el-option v-for="item in StatusModules"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="创建日期:"
                              style="margin-left: -70px;">
                  <el-date-picker v-model="madedate"
                                  style="width: 70%;"
                                  type="daterange"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item label="计划完成时间:"
                              style="margin-left: -70px;">
                  <el-date-picker v-model="plandate"
                                  style="width: 70%;"
                                  type="daterange"
                                  range-separator="至"
                                  start-placeholder="开始日期"
                                  end-placeholder="结束日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item style="margin-left: -100px;">
                  <el-button size="mini"
                            type="goon"
                            round
                            icon="el-icon-search"
                            @click="getManagerChecks">查询</el-button>
                  <el-button size="mini" type="normal" round icon="el-icon-download" @click="handleExport">导出</el-button>
                    
                </el-form-item>
              </el-form>
              <el-form>
                <el-form-item>
                  <el-collapse style="margin-left:3px;width:99%;">
                    <el-collapse-item  name="1">
                      <template slot="title">
                        <font style="font-weight: normal;"><i class="el-icon-setting"></i>导出配置</font>
                      </template>
                      <el-form-item label="每页显示条数:">
                        <el-input-number size="mini" v-model="pageSize" :min="20" :step="5" :max="300" label="安抚"></el-input-number>
                        <el-switch style="margin-left:5px" v-model="showDesc" active-text="显示描述" inactive-text=""></el-switch>
                        <el-switch style="margin-left:5px" v-model="showPingfen" active-text="显示评分" inactive-text="" @change="handleSwitchPingfen"></el-switch>
                        <el-switch style="margin-left:5px" v-model="showCreatetime" active-text="显示创建时间" inactive-text="" ></el-switch>
                        <el-switch style="margin-left:5px" v-model="showLastProgresstime" active-text="显示维护时间" inactive-text="" ></el-switch>
                      </el-form-item>
                    </el-collapse-item>
                  </el-collapse>
                </el-form-item>
              </el-form>
            <!-- </el-collapse-item>
          </el-collapse> -->
        <el-form :inline="true"
                 style="margin-left: 100px"
                 size="mini">
        </el-form>

        <!--列表-->
        <el-table 
                  id="mytable"
                  :data="users"
                  highlight-current-row
                  row-key="Id"
                  lazy
                  :indent="30"
                  :load="load"
                  :tree-props="{children: 'children',hasChildren: 'IsHasChildren'}"
                  :fit="true"
                  :row-style="{height:'55px'}"
                  :cell-style="mycellStyle"
                  :header-cell-style="{background:'#dce4f1',color:'#000000'}"
                  @current-change="selectCurrentRow"
                  @row-dblclick="checkInfo"
                  @cell-mouse-enter="tabmouseEnter"
                  @cell-mouse-leave="tabmouseLeave"
                  style="width: 100%">
          <!-- <el-table-column type="index"></el-table-column> -->
          <el-table-column type="index" width="6">
            <template scope="scope">
            </template>
          </el-table-column>
          <el-table-column label="工作项目"
                           prop="WorkItem"
                           min-width="440px"
                           >
              <template slot-scope="scope">

                <span :style="{'cursor':'default','display':'block','padding-left':'20px','margin-top':scope.row.IsHasChildren?'-22px':'0px','margin-bottom':scope.row.IsHasChildren ?'0px':'15px','margin-left': scope.row.MarginLeft+'px' }">{{scope.row.Number}}、{{scope.row.WorkItem}}</span>
                <!-- <span :style="{'cursor':'default'}">{{scope.row.Number}}、{{scope.row.WorkItem}}</span> -->
                <!-- <span v-if="!scope.row.WorkDescription" ><el-button @click="handleCollect(scope.row)" style="color:#FF9800;margin-right:3px;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null"  :title="scope.row.CollectId==null?'特别关注':'已特别关注'"  type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>{{scope.row.WorkItem}}</span>
                <el-tooltip v-else placement="right-start" effect="light" :enterable="true">
                  <div slot="content" v-html="'<span style=\'color:#999\' >工作项目:</span><br/>'+scope.row.WorkItem+ '<br/><span style=\'color:#999\' >工作描述:</span><br/>'+scope.row.WorkDescription.replace(/\n/g,'<br/>')+ '<br/><span style=\'color:#999\' >创建于:</span><br/>'+scope.row.CreateTime+ '<br/><span style=\'color:#999\' >计划完成时间:</span><br/>'+scope.row.PlanComplateTime+ '<br/><span style=\'color:#999\' >进度:</span><br/>'+formatProgress(scope.row)+ '<br/><span style=\'color:#999\' >最新进度:</span><br/>'+(scope.row.LastProgressDescription==null?'':scope.row.LastProgressDescription.replace(/\n/g,'<br/>'))" ></div>
                  <el-button style="color:black;cursor:default" type="text"><el-button @click="handleCollect(scope.row)" style="margin-right:3px;color:#FF9800;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null"  :title="scope.row.CollectId==null?'特别关注':'已特别关注'"  type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>{{scope.row.WorkItem}}</el-button>
                </el-tooltip> -->
              </template>
          </el-table-column>
          <el-table-column label="工作描述"
                           prop="WorkDescription"
                           min-width="150px"
                           v-if="showDesc" ></el-table-column>
          <el-table-column label="计划完成时间"
                           prop="PlanComplateTime"
                           min-width="115px"
                           :formatter="formatPlanComplateTime">
            <template slot-scope="scope">
              <div v-if="new Date()>new Date(new Date(new Date(scope.row.PlanComplateTime).toLocaleDateString()).getTime()+24*60*60*1000-1)&&scope.row.TaskProgressValue<100">
                <font color=red>{{formatPlanComplateTime(scope.row)}}</font>
                &nbsp;<el-tag v-if="scope.row.IsProlongTime" type="success" size="mini">延</el-tag>
              </div>
              <div v-else>
                <font color=black>{{formatPlanComplateTime(scope.row)}}</font>
                &nbsp;<el-tag v-if="scope.row.IsProlongTime" type="success" size="mini">延</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="责任人"
                           prop="PersonOfDuty"
                           min-width="70px"></el-table-column>
          <el-table-column label="任务状态"
                           prop="Status"
                           align="center"
                           min-width="99px">
            <template slot-scope="scope">
                <div>
                <div v-if="scope.row.TaskProgressValue<100"  style="font-size:10px;">进度:{{formatProgress(scope.row)}}</div>
                  <el-tag v-if="scope.row.Status == 0"
                          effect="dark"> 进行中</el-tag>
                  <el-tag v-if="scope.row.Status == 2"
                          effect="dark"
                          type="success">已完成</el-tag>
                  <el-tag v-if="scope.row.Status == -1"
                          effect="dark"
                          type="info">已取消</el-tag>
                  <el-tag v-if="scope.row.Status == 3"
                          effect="dark"
                          type="danger">退回</el-tag>
                  <el-tag v-if="scope.row.Status == 1"
                          effect="dark"
                          type="warning">已完成审核中</el-tag>
                  <el-tag v-if="scope.row.Status == 4"
                          effect="dark"
                          type="warning">新建任务待审核</el-tag>
                  <el-tag v-if="scope.row.Status == 5"
                          effect="dark"
                          type="danger">取消任务待审核</el-tag>
                  <el-tag v-if="scope.row.Status == 6"
                          effect="dark"
                          type="danger">新建任务退回</el-tag>
                  <el-tag v-if="scope.row.Status == 8"
                          effect="dark"
                          type="warning">阅知审核中</el-tag>
                  <el-tag v-if="scope.row.Status == 9"
                          effect="dark"
                          type="success">审核完成</el-tag>
                </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="进度"
                           prop="TaskProgressValue"
                           min-width="60px"
                           :formatter="formatProgress"></el-table-column> -->
          <el-table-column label="任务类型"
                           prop="CommonTaskClassifyText"
                           min-width="90px"
                           :formatter="formatCommonTaskClassifyText"></el-table-column>
          <!-- <el-table-column label="任务来源"
                           prop="Source"
                           :formatter="formatSource"
                           min-width="80px"></el-table-column> -->
          <!-- <el-table-column label="创建时间"
                           prop="CreateTime"
                           min-width="90px"
                           :formatter="formatCreateTime">
            <template slot-scope="scope">
              <el-tooltip placement="top">
                <div slot="content">
                  {{ formatTipCreateTime( scope.row) }}
                </div>
                <div>
                  {{ formatCreateTime( scope.row) }}
                </div>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="维护时间" prop="LastInputProcess" min-width="80px" :formatter = "formatLastInputProcessTime">
              <template slot-scope="scope">
                  <el-tooltip placement="top">
                      <div slot="content">
                          {{ formatTipLastInputProcessTime( scope.row) }}
                      </div>
                      <div>
                          {{ formatLastInputProcessTime( scope.row) }}
                      </div>
                  </el-tooltip>
              </template>
          </el-table-column> -->
        <el-table-column label="周期"
                         prop="CreateTime"
                         min-width="110"
                         :formatter="formatCreateTime">
          <template slot-scope="scope">
            <el-tooltip placement="left" effect="light">
              <div slot="content">
                {{ formatTipCreateTime( scope.row) }}
              </div>
              <div style="font-size:9px;cursor:default">
                {{ formatCreateTime( scope.row) }}创建
              </div>
            </el-tooltip>
            <el-tooltip placement="left" effect="light">
                <div slot="content">
                    {{ formatTipLastInputProcessTime( scope.row) }}
                </div>
                <div style="font-size:9px;cursor:default">
                    {{ formatLastInputProcessTime( scope.row) }}
                </div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" prop="CreateTime" v-if="showCreatetime"></el-table-column>
        <el-table-column label="维护时间" prop="LastProgressTime" v-if="showLastProgresstime"></el-table-column>
          <el-table-column label="最新进度"
                          prop="LastProgressDescription" show-overflow-tooltip
                          min-width="100px"></el-table-column>
          <el-table-column label="评分意见"
                          prop="ScoreContent"
                           v-if="showPingfenDesc"
                          min-width="100px"></el-table-column>
          <el-table-column label="任务评分"
                          prop="Score" 
                           v-if="showPingfenScore"
                          min-width="80px">
            <template slot-scope="scope">
              <div v-if="scope.row.Score==0"></div>
              <div v-else-if="scope.row.Score==1">A</div>
              <div v-else-if="scope.row.Score==2">B</div>
              <div v-else-if="scope.row.Score==3">C</div>
              <div v-else-if="scope.row.Score==4">D</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" prop="Operation" min-width="100px">
            <template slot-scope="scope">
                <el-button type="text" v-show="scope.row.PersonOfDuty!=''"
                           size="medium"
                           @click="checkInfo(scope.row)">查看</el-button>
                <el-button @click="handleCollect(scope.row)" style="color:#FF9800;margin-right:3px;" size="mini" v-show="(collectBtn&&collectRowId==scope.row.Id) || scope.row.CollectId!=null" :title="scope.row.CollectId==null?'特别关注':'已特别关注'" type="default" :icon="scope.row.CollectId==null?'el-icon-star-off':'el-icon-star-on'" circle></el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <el-pagination align='center'
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-size="pageSize"
                       layout="prev, pager, next, jumper"
                       :page-count="total"
                       :total="totaldata">
        </el-pagination>



        <taskinfo v-if="checkVisible" :data="checkForm" @closeFunction="checkclose" type="normal" ></taskinfo>


        <el-dialog :visible.sync="chooseDptVisable"
                   v-model="chooseDptVisable"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   @opened="onDptOpened"
                   style="width: 145%; left: -300px; top:-80px"
                   center>
          <template slot="title">
            <span style="color:#FFFFFF;font-size: 16px;">选择查看部门</span>
          </template>
              <el-input
                size="mini"
                style="width:220px;margin-left:10px;margin-bottom:2px;"
                :clearable="true"
                placeholder="输入部门名称关键字进行筛选"
                v-model="filterDptText">
              </el-input>
              <el-tree
              :data="alldepartmentdata"
              show-checkbox
              :expand-on-click-node="false"
              :default-expand-all="false"
              :check-strictly="false"
              :check-on-click-node="true"
              :filter-node-method="filterNode"
              highlight-current
              :indent="30"
              @check="handleCheck"
              node-key="id"
              ref="tree1"
            ></el-tree>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click.native="handleChooseOKDpt">选择</el-button>
            </div>
          </el-dialog>


        <el-dialog :visible.sync="chooseGroupPersonVisiable"
                   v-model="chooseGroupPersonVisiable"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   @opened="onGroupPersonDialogOpened"
                   style="width: 100%; left: -100px; top:-80px"
                   center>
          <template slot="title">
            <span style="color:#FFFFFF;font-size: 16px;cursor:default">查阅常用成员</span>
          </template>
          <el-empty v-show="groupPersonTags.length==0" description="暂未设置常用成员">
            <el-button @click="handleAddGroupPerson" class="button-new-tag" icon="el-icon-plus" size="small" >添加成员</el-button>
          </el-empty>
          <el-tag :key="item.userId"
            v-for="item in groupPersonTags" closable
            :disable-transitions="false"
            @close="handleRemovePerson(item)">{{item.userName}}</el-tag>
            <el-button style="margin-left:5px;margin-top:5px" v-show="groupPersonTags.length>0" @click="handleAddGroupPerson" class="button-new-tag" icon="el-icon-plus" size="mini" >添加成员</el-button>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" @click.native="handleChooseGroupPerson">使用</el-button>
            </div>
        </el-dialog>

        <el-dialog title="选择人员" :visible.sync="addUserVisible" v-model="addUserVisible" width="75%" center append-to-body>
          <UserChoose :data="choosedusers" :all="true" :single="false" @callback="chooseUserCallBack"></UserChoose>
        </el-dialog>

      </div>
    </el-col>
  </section>
</template>
<script>
import util from '../../../util/date';
import FileSaver from "file-saver";
import XLSX from "xlsx";
import xlsxstyle from "xlsx-style";
import {  AddTaskCollect,RemoveTaskCollect,AddTaskProgressReview,QueryReviewByProgressId, GetChildrenList, GetManagePost, GetDepartmentList, GetDepartmentTypes, QueryPageTasksByWhere20210126,QueryPageTasksByWhere20220601 , QueryTasksById, QueryTaskProgressByTaskId, QueryYearPlanAboutTaskByYearplanId, QueryYearPlanChildTasksByParentId, QueryChildTasksByParentId, GetUserTopDepartmentList,QuerySearchQuanxianListByUsercode,GetDepartmentPostUserList,AddGroupPersonItem,QueryGroupPersonListByCreatedUser,DeleteGroupPersonById } from "../../api/oa"
import UserChoose from '../../components/UserChoose'
import taskinfo from "../../components/taskinfo.vue"
import { GetDepartmentTree } from "../../api/hr";
export default {
  components: { taskinfo,UserChoose },
  data () {
    return {
      groupPersonTags:[],
      showDesc:false,
      myoptions:[],
      myvalue: [],
      alldepartmentdata: [],
      radioOptions:false,
      currentActiveId: null,
      currentPage: 1, // 当前页码
      total: null, // 总条数
      totaldata: null,
      pageSize: 20, // 每页的数据条数
      page: 1,
      DepartcurrentPage: 1, // 当前页码
      Departtotal: null, // 总条数
      Departtotaldata: null,
      DepartpageSize: 800, // 每页的数据条数
      Departpage: 1,
      departmentsearchdata: [],
      TypeIdModules: [],   //部门类型集合
      users: [],
      departusers: [],
      departmentList: [],
      DptProps: {
        label: 'Name',
        isLeaf: 'Leaf'
      },
      currentRow: null,
      DepartUserRow: null,
      workItem: null,
      taskClassify: [],
      progressWhere: 0,
      taskStatus: 0,
      progressValue: null,
      deptinput: null,
      madedate: null,
      plandate: null,
      cascadervalue: null,
      progressValueWhere: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: ">="
        },
        {
          value: 3,
          label: "="
        },
        {
          value: 2,
          label: "<="
        },
        {
          value: 4,
          label: "<"
        }
      ],
      ClassifyModules: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "日常工作",
          children:[{
              value: 11,
              label: '日常工作'
          },{
              value: 12,
              label: '流程批复'
          },{
              value: 13,
              label: '临时工作'
          },{
              value: 14,
              label: '月度计划'
          },{
              value: 15,
              label: '会议项目'
          }]
        },
        {
          value: 3,
          label: "例会项目"
        },
        {
          value: 4,
          label: "年度计划"
        },
        {
          value: 5,
          label: "专项会议"
        },
      ],
      StatusModules: [
        {
          value: 0,
          label: "全部"
        },
        {
          value: 1,
          label: "进行中"
        },
        {
          value: 2,
          label: "已完成"
        },
      ],
      checkVisible: false,
      checkForm: {            //查看表单初始化
        Id: null,
        WorkItem: null,
        WorkDescription: null,
        PlanComplateTime: null,
        Remark: null,
        IsNeedApproval: null
      },
      showAssign: false,
      createPerson: false,                    //创建人是否显示
      showyearplan: false,                    //年度计划查看界面显示控制
      showorganize: false,                     //跨部门协同查看界面显示控制
      showregularproject: false,               //例会来源查看界面控制
      showprofessionmeetong: false,            //专项会议查看界面控制
      CommonTaskClassifyText: null,
      pValue1: null,   //进度条数值
      istaskpro: false,
      btntext:'选择部门',
      chooseDptVisable:false,
      chooseGroupPersonVisiable:false,
      alldepartmentdata:[],
      tasks: [],
      options: [],
      collActive:['1'],
      checkdepartid: null,
      departuserid: null,
      myuserid:'',
      filterDptText:'',
      collectBtn:false,
      collectRowId:'',
      props: {
        pagethis:this,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          let self=this.pagethis;
          //console.log(self)
          const { level, value } = node;
          if (node.level == 0) {
            var user = JSON.parse(window.localStorage.user);
            var userid = user.sub;
            QuerySearchQuanxianListByUsercode({ usercode: userid }).then(res => {
              // console.log(res);
              var dataArr=res.data.response;
              var nodes=new Array();
              var n=0;
              for(var d=0;d<dataArr.length;d++)
              {
                if(dataArr[d].Type==99 || dataArr[d].Type==1)
                {
                  var dptIds=dataArr[d].DepartmentCode;
                  var dptNames=dataArr[d].DepartmentName;
                  var dptIdArr=dptIds.split(',');
                  var dptNameArr=dptNames.split(',');
                  for(var a=0;a<dptIdArr.length;a++)
                  {
                    if(dptIdArr[a].length>0)
                    {
                      console.log(dptIdArr[a]);

                      
                      GetDepartmentTree({deptId:dptIdArr[a]}).then((res) => {
                        if (res.data.code==200) {
                          console.log(res.data.data.dept)
                          // self.alldepartmentdata=(res.data.data.dept);
                          for(var d=0;d<res.data.data.dept.length;d++)
                          {
                            self.alldepartmentdata.push(res.data.data.dept[d]);
                          }
                          // self.alldepartmentdata.push(res.data.data.dept);
                          console.log(self.alldepartmentdata)
                          // this.alldepartmentdata = res.data.data.dept;
                          // console.log(res.data.response.children);
                        }
                      });
                      return;
                      var obj={};
                      obj.label=dptNameArr[a];
                      obj.value=dptIdArr[a];
                      obj.leaf=false;
                      nodes=nodes.filter(function (o, index, array) {
                          return o.value!=obj.value
                      })
                      nodes.push(obj);
                    }
                  }
                  n++;
                }
              }
              if(n>0)
              {
                resolve(nodes);
                if(nodes.length>0)
                {
                  var value=nodes[0].value;
                  self.topDepartment=value;
                }
              }else{
                GetUserTopDepartmentList({ userId: userid }).then(res => {
                  const nodes = res.data.data.map(x=>({
                      value: x.deptId,
                      label: x.deptName,
                      leaf: !x.hasNode
                  }))
                  resolve(nodes);
                  if(res.data.data.length>0)
                  {
                    var value=res.data.data[0].Id;
                    self.topDepartment=value;
                  }
                });
              }
            });

          } else {
            // var cv=node.value;
            // GetChildrenList({ deptId: node.value }).then(res => {
            //   // console.log(res)
            //   // const nodes = res.data.response.map(x => ({
            //   //   value: x.Id,
            //   //   label: x.Name,
            //   //   leaf: x.Leaf
            //   // }))
            //   if(res.data.data.dept.length>0)
            //   {
            //     if(cv != res.data.data.dept[0].id)
            //     {
            //       const nodes = res.data.data.dept.map(x => ({
            //         value: x.id,
            //         label: x.label,
            //         leaf: false
            //       }))
            //       resolve(nodes)
            //     }
            //   }
            // })


              GetChildrenList({deptId: node.value}).then(res => {
                  // console.log(res);
                  alert()
                  var mydata = res.data.data;
                  if(mydata.dept[0].children!=null)
                  {
                      const nodes = res.data.data.dept[0].children.map(x=>({
                          value: x.id,
                          label: x.label,
                          leaf: !x.hasChildren
                      }))
                      // console.log(nodes);
                      resolve(nodes)   
                  }else{
                      resolve([]);
                  }
              })



          }

        }
      },
      topDepartment:'',
      YearPlanTaskList: [],
      YearPlanTask: null,
      YearPlanTaskId: null,
      content:'',
      mapReview:new Map(),
      showPingfen:false,
      showCreatetime:false,
      showLastProgresstime:false,
      showPingfenScore:false,
      showPingfenDesc:false,
      mapLevel:new Map(),
      addUserVisible:false,
      choosedusers:[],
      needName:''
    }
  },
  methods: {
    //监听子组件事件 选择人员回调
    chooseUserCallBack(data) {
      let self = this;
      // console.log(data);
      var newdata = data.map(function (item) {
        return item['Id']
      })
      var namenewdata = data.map(function (item) {
        return item['UserNameClaim']
      })
      // console.log(newdata);

      var str = ''
      for (var i = 0; i < namenewdata.length; i++) {
        str += namenewdata[i] + ','
      }
      if (str.length > 0) {
        str = str.substr(0, str.length - 1)
      }

      var idstr = ''
      for (var i = 0; i < newdata.length; i++) {
        idstr += newdata[i] + ','
      }
      if (idstr.length > 0) {
        idstr = idstr.substr(0, idstr.length - 1)
      }
      this.needName = str
      // this.addForm.AssignCode = idstr
      // this.addForm.AssignName = str
      this.addUserVisible = false
      var user = JSON.parse(window.localStorage.user);
      var userCode = user.sub;
      var userName = user.name;
      var arr = [];
      for(var a=0;a<newdata.length;a++){
        var filters = self.groupPersonTags.filter(t=>t.userId==newdata[a]);
        if(filters.length>0){
          continue;
        }
        var obj={
          PersonUserId:newdata[a],
          PersonName:namenewdata[a],
          CreatedId:userCode,
          Type:1,
          CreatedBy:userName
        }
        arr.push(obj);
      }
      AddGroupPersonItem(arr).then((res)=>{
        // console.log(res);
        if(res.status==200){
          if(res.data.success){
            this.$message({
              message: '添加完成',
              type: 'success'
            });
            self.loadGroupPerson();
          }
        }
      })
    },
    handleAddGroupPerson(){
      this.addUserVisible = true;
    },
    handleRemovePerson(item){
      console.log(item)
      let self = this;
      DeleteGroupPersonById({id:item.id}).then((res)=>{
        console.log(res);
        if(res.status==200){
          if(res.data.success){
            this.$message({
              message: '已移除',
              type: 'success'
            });
            self.loadGroupPerson();
          }
        }
      })
    },
    handleChooseGroupPerson(){
      let self = this;
      self.departusers=[];
      self.myoptions=[];
      for(var a=0;a<self.groupPersonTags.length;a++){
        let item=self.groupPersonTags[a];
        let obj = { value: item.userId, label: item.userName };
        self.departusers.push({ id:item.userId,name:item.userName });
        self.myoptions.push(obj);
      }
      this.$message({
        message: '选择完成，点击查询进行查阅',
        type: 'success'
      });
      self.btntext='选择部门';
      self.chooseGroupPersonVisiable = false;
    },
    loadGroupPerson(){
      let self = this;
      var user = JSON.parse(window.localStorage.user);
      var userCode = user.sub;
      QueryGroupPersonListByCreatedUser({userCode:userCode,type:1}).then((res)=>{
        // console.log(res)
        if(res.status==200){
          var data = res.data.response;
          self.groupPersonTags=[]
          data.forEach(element => {
            self.groupPersonTags.push({
              id:element.Id,
              userId:element.PersonUserId,
              userName:element.PersonName
            })
          });
        }
      })
    },
    onGroupPersonDialogOpened(){
      let self = this;
      self.loadGroupPerson();
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    mycellStyle(row,column,rowIndex,columnIndex)
    {
      // return 'background:red'
      // console.log(row);
      if(row.row.Level===1)
      {
        return 'background:#fff'
      }
      else if(row.row.Level===2)
      {
        return 'background:#e9f3ff'
      }
      else if(row.row.Level===3)
      {
        return 'background:#d4e9ff';
      }
      else if(row.row.Level===4)
      {
        return 'background:#bfdeff';
      }
      else if(row.row.Level===5)
      {
        return 'background:#aad2ff';
      }
      else if(row.row.Level===6)
      {
        return 'background:#96c8ff';
      }
      else if(row.row.Level===7)
      {
        return 'background:#81bdff';
      }
      else if(row.row.Level===8)
      {
        return 'background:#6eb3ff';
      }
      else if(row.row.Level===9)
      {
        return 'background:#5ba8ff';
      }
      else if(row.row.Level===10)
      {
        return 'background:#499eff';
      }
      return ''
    },
    handleSwitchPingfen(val)
    {
      this.showPingfenScore=val;
      this.showPingfenDesc=val;
    },
    handleCollect(row)
    {
      if(row.CollectId==null)
      {
        var para={};
        para.TaskID=row.Id;
        var user = JSON.parse(window.localStorage.user);
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para)
        // return;
        AddTaskCollect(para).then((res) => {
          this.$message({
            message: '已特别关注',
            type: 'success'
          });
          // console.log(res);
          var id=res.data.response;
          row.CollectId=id;
        })
      }else{
        this.$confirm("确定要关闭对此任务的关注吗？", "提示", {}).then(() => {
          var para={};
          para.Id=row.CollectId;
          para.TaskID=row.Id;
          RemoveTaskCollect(para).then((res) => {
            this.$message({
              message: '已取消关注',
              type: 'success'
            });
            row.CollectId=null;
          })
        }).catch(()=>{});
      }
    },
    tabmouseEnter(row, column, cell, event)
    {
      if(row.PersonOfDuty=='')
      {
        return;
      }
      if(column.property!='Operation')return;
      this.collectBtn=true;
      this.collectRowId=row.Id;
      // console.log(row)
    },
    tabmouseLeave(row, column, cell, event)
    {
      this.collectBtn=false;
      this.collectRowId='';
    },
    handleChange(val)
    {
      // console.log(val)
      if(val.length>0)
      {
        var progressId = val[0];
        this.loadReview(progressId);
      }
    },
    catchData(c)
    {
      // console.log(c)
      this.content=c;
    },
    handlePopoShow()
    {
      
    },
    handlePopoReplyShow()
    {

    },
    myname()
    {
      var user = JSON.parse(window.localStorage.user);
      return user.name;
    },
    handleReplyReview(progress,rid)
    {
      // console.log(progress);
      // console.log(rid);
      let self=this;
      var progressId = progress.Id;
      var user = JSON.parse(window.localStorage.user);
      var para={};
      para.TaskID=this.checkForm.Id;
      para.ProgressID=progressId;
      para.ReplayID = rid;
      para.ReviewContent = self.content;
      para.CreatedId = user.sub;
      para.CreatedBy = user.name;
      // console.log(para);

      AddTaskProgressReview(para).then((res) => {
        // console.log(res);
        if (res.data.success) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          });
          for(var a=0;a<self.tasks.length;a++)
          {
            if(this.tasks[a].Id==progressId)
            {
              this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
              break;
            }
          }
          this.loadReview(progressId);
        } else {
          this.$message({
            message: res.data.msg,
            type: 'error'
          });
        }
      })
    },
    handleSendReview(progress)
    {
      let self=this;
      // this.$confirm('评论即将发表，确定吗？', '提示', {}).then(() => 
      {
        var progressId = progress.Id;
        var user = JSON.parse(window.localStorage.user);
        var para={};
        para.TaskID=this.checkForm.Id;
        para.ProgressID=progressId;
        para.ReplayID = null;
        para.ReviewContent = self.content;
        para.CreatedId = user.sub;
        para.CreatedBy = user.name;
        // console.log(para);

        AddTaskProgressReview(para).then((res) => {
          // console.log(res);
          if (res.data.success) {
            this.$message({
              message: res.data.msg,
              type: 'success'
            });
            for(var a=0;a<self.tasks.length;a++)
            {
              if(this.tasks[a].Id==progressId)
              {
                this.tasks[a].ReviewCount=this.tasks[a].ReviewCount+1;
                break;
              }
            }
            this.loadReview(progressId);
          } else {
            this.$message({
              message: res.data.msg,
              type: 'error'
            });
          }
        })
      }
      // )
    },
    loadReview(progressid)
    {
      let self=this;
      // console.log(progressid)
      let para = { progressId: progressid };
      QueryReviewByProgressId(para).then((res) => {
        // console.log(res)
        var data = res.data.response;
        // console.log(data);
        self.mapReview.set(progressid,data);
        // console.log(self.mapReview);
        this.$forceUpdate()
        // for(var a=0;a<data.length;a++)
        // {
        //   self.mapReview.set(progressid,data);
        // }
      });
    },
    getReplyReview(arr,id)
    {
      // console.log(arr);
      // console.log(id);
      var o ={
        CreatedBy:'',
        CreatedId:'',
        CreatedTime:'',
        ReviewContent:''
      };
      for(var i=0;i<arr.length;i++)
      {
        if(arr[i].Id==id)
        {
          o = arr[i];
          break;
        }
      }
      return o;
    },
    handleRadioOptionChange()
    {
      if(this.checkdepartid!=null)
      {
        this.getDepartUsers(this.checkdepartid);
      }
    },
    handleProcessSelectChange (taskid, index) {
      this.currentActiveId = taskid;
      this.currentProgressRowIndex = index;
    },
    showTaskFile (file) {
      window.open(file, "_blank");
    },
    //组织维护加载节点的子节点集合
    loadDptChildNode (node, resolve) {
      let para = { deptId: node.data.Id };
      GetChildrenList(para).then((res) => {
        const roledata = res.data.response;
        resolve(roledata);
      });
    },
    //部门树点击
    dptTreeNodeClick (node) {
      this.getManagerChecks();
    },
    //部门查询按钮
    departmentFind () {
      let para = { name: this.deptinput };
      GetDepartmentList(para).then((res) => {
        var myArray = new Array();
        for (var i = 0; i < res.data.response.length; i++) {
          myArray.push(res.data.response[i]);
        }
        this.departmentsearchdata = myArray;
        this.$refs.tree1.setCurrentKey(null);
      });
    },
    //刷新当前选择的部门树节点，末级节点刷新父节点
    refreshDptTreeNodeAfterAdd () {
      //当前选中
      var treeid = this.$refs.tree1.getCurrentKey();
      if (treeid) {
        //当前选中节点
        var cunode = this.$refs.tree1.getNode(treeid);
        //叶子节点
        if (cunode.isLeaf) {
          var pdtreeid = cunode.data.ParentId;
          //顶级节点或没有父节点
          if (cunode.level == 1 || pdtreeid == undefined) {
            //全部重新加载
            let para = { deptId: null };
            GetChildrenList().then((res) => {
              const initdptdata = res.data.response;
              this.departmentsearchdata = initdptdata;
              this.$refs.tree1.setCurrentKey(null);
            });
          } else {
            //重新加载当前节点的父节点
            var pdnode = this.$refs.tree1.getNode(pdtreeid);
            this.refreshLazyTree(pdnode);
          }
        } else {
          //不是叶子结点，重新加载当前节点的子节点
          this.refreshLazyTree(cunode);
        }
      } else {
        let para = { deptId: null };
        GetChildrenList().then((res) => {
          const initdptdata = res.data.response;
          this.departmentsearchdata = initdptdata;
          this.$refs.tree1.setCurrentKey(null);
        });
      }
    },
    //懒加载刷新节点
    refreshLazyTree (node) {
      //非末级节点清空子节点
      if (!node.isLeaf) {
        var theChildren = node.childNodes;
        theChildren.splice(0, theChildren.length);
      }
      node.loaded = false;
      node.expand();
    },
    //部门树节点渲染
    renderContent (h, { node, data, store }) {
      var icon = "";
      for (var i = 0; i < this.TypeIdModules.length; i++) {
        var tm = this.TypeIdModules[i];
        if (tm.Id == data.TypeId) {
          icon = tm.Icon;
          break;
        }
      }
      return (
        <span class="custom-tree-node">
          <i class={icon}>{node.label}</i>
        </span>
      )
    },
    selectCurrentRow (val) {
      this.currentRow = val;
    },
    selectDepartUserRow (val) {
      this.DepartUserRow = val;
      this.departuserid = val.UserId;
      this.YearPlanTaskId = null;
      this.getManagerChecks();
    },
    YearPlanchange (val) {
      this.YearPlanTask = val.split('|')[2];
      this.YearPlanTaskId = val.split('|')[1];
    },
    rowformat(element,pid)
    {



      var parentLevel = 0;
      if(element.NoTask)
      {
        console.log('notask')
        var tag = element.TagType<=1?element.Tag:'';
        var has = this.mapLevel.has(tag)
        // var has = this.mapLevel.has(element.Tag)
        if(has)
        {
          parentLevel = this.mapLevel.get(tag);
          // parentLevel = this.mapLevel.get(element.Tag);
        }
        else
        {
          parentLevel = this.mapLevel.get(element.ParentId);
        }
      }
      else
      {
        console.log('yestask')
        if(pid)
        {
          if(pid!=element.TaskId)
          {
            parentLevel = this.mapLevel.get(pid);
          }else{
            parentLevel = this.mapLevel.get(element.TaskId);
          }
        }else{
          // parentLevel = this.mapLevel.get(element.ParentId);
          var tag = element.TagType<=1?element.Tag:'';
          var has = this.mapLevel.has(tag)
          if(has)
          {
            parentLevel = this.mapLevel.get(tag);
          }
          else
          {
            parentLevel = this.mapLevel.get(element.ParentId);
          }
        }
      }


      // if(element.NoTask)
      // {
      //     parentLevel = this.mapLevel.get(tag);
      // }else
      // {
      //   var tag = element.TagType<=1?element.Tag:'';
      //   var has = this.mapLevel.has(tag)
      //   // var has = this.mapLevel.has(element.Tag)
      //   var parentLevel = 0;
      //   if(has)
      //   {
      //     parentLevel = this.mapLevel.get(tag);
      //   }
      //   else
      //   {
      //     parentLevel = this.mapLevel.get(element.ParentId);
      //   }
          
      // }
      var level = parentLevel+1;
      if(level==2)
      {
        element.MarginLeft = 30;
      }else if(level == 3){
        element.MarginLeft = 60;
      }else if(level == 4){
        element.MarginLeft = 90;
      }else if(level == 5){
        element.MarginLeft = 120;
      }else if(level == 6){
        element.MarginLeft = 150;
      }else if(level == 7){
        element.MarginLeft = 180;
      }else if(level == 8){
        element.MarginLeft = 210;
      }else if(level == 9){
        element.MarginLeft = 240;
      }else if(level == 10){
        element.MarginLeft = 270;
      }
      element.Level = level;
      // this.mapLevel.set(element.TaskId,level);
      if(element.TaskId)
      {
        this.mapLevel.set(element.TaskId,level);
        console.log('a')
      }else{
        console.log('b')
        this.mapLevel.set(element.Id,level);
      }
      return element;
    },
    rowformat2(element,pid)
    {
      var parentLevel = 0;
      if(element.NoTask==0)
      {
        var tag = element.TagType<=1?element.Tag:'';
        var has = this.mapLevel.has(tag)
        // var has = this.mapLevel.has(element.Tag)
        if(has)
        {
          parentLevel = this.mapLevel.get(tag);
          // parentLevel = this.mapLevel.get(element.Tag);
        }
        else
        {
          parentLevel = this.mapLevel.get(element.ParentId);
        }
      }
      else
      {
        if(pid!=element.TaskId)
        {
          parentLevel = this.mapLevel.get(pid);
        }else{
          parentLevel = this.mapLevel.get(element.TaskId);
        }
      }
      var level = parentLevel+1;
      if(level==2)
      {
        element.MarginLeft = 30;
      }else if(level == 3){
        element.MarginLeft = 60;
      }else if(level == 4){
        element.MarginLeft = 90;
      }else if(level == 5){
        element.MarginLeft = 120;
      }else if(level == 6){
        element.MarginLeft = 150;
      }else if(level == 7){
        element.MarginLeft = 180;
      }else if(level == 8){
        element.MarginLeft = 210;
      }else if(level == 9){
        element.MarginLeft = 240;
      }else if(level == 10){
        element.MarginLeft = 270;
      }
      element.Level = level;
      this.mapLevel.set(element.TaskId,level);
      return element;
    },
    load (tree, treeNode, resolve) {
      var notaskType = tree.NoTask;
      var mytaskid = tree.TaskId;
      let para = {
        // taskId: tree.TaskId,
        taskId: mytaskid,
      };
      if (tree.PersonOfDuty == '') {
        para.notaskType = notaskType;
      }
      else {
        para.notaskType = 0;
      }


      // var mytaskid = tree.TaskId?tree.TaskId:tree.Id;
      // let para = {
      //   taskId: mytaskid
      // };
      if(tree.IsHasChildrenYearplan && tree.IsHasNormalTasks)
      {
        console.log(1)
        QueryYearPlanChildTasksByParentId(para).then((res) => {
          // console.log(res.data.response);
          var myarr = new Array();
          var number = 0;
          res.data.response.forEach(element => {
            // console.log(element)
            // console.log(element.IsHasNormalTasks+","+element.IsHasChildrenYearplan);
            number++;
            element = this.rowformat(element);
            element.Number = number;
            if(element.IsHasNormalTasks || element.IsHasChildrenYearplan)
            {
              element.IsHasChildren = true;
            }else{
              element.IsHasChildren = false;
            }
            myarr.push(element)
          });
          resolve(myarr);
        });
      }else if(tree.IsHasChildrenYearplan)
      {
        console.log(2);
        QueryYearPlanChildTasksByParentId(para).then((res) => {
          // console.log(res.data.response);
          var myarr = new Array();
          var number = 0;
          res.data.response.forEach(element => {
            element.Number = ++number;
            element = this.rowformat(element);
            // console.log(element);
            // console.log(element.IsHasNormalTasks+","+element.IsHasChildrenYearplan);
            if(element.IsHasNormalTasks || element.IsHasChildrenYearplan)
            {
              element.IsHasChildren = true;
            }else{
              element.IsHasChildren = false;
            }
            myarr.push(element)
          });
          resolve(myarr);
        });
        // QueryYearPlanChildTasksByParentId(para).then((res) => {
        //   resolve(res.data.response)
        // });
      }else if(tree.IsHasNormalTasks)
      {

        console.log(3)
        let para2={
          sort: 1,
          yearplantaskid: tree.Id,
          // yearplantaskid: mytaskid,
          taskclassify:0,
          month:'',
          month2:'',
          pageIndex: 1,
          pageSize: 500
        };
        QueryYearPlanAboutTaskByYearplanId(para2).then((res) => {
          // console.log(res.data.response.data);
          var myarr = new Array();
          var number = 0;
          res.data.response.data.forEach(element => {
            element.Number = ++number;
            element = this.rowformat(element);
            myarr.push(element)
          });
          resolve(myarr);
          // resolve(res.data.response.data)
        });
      }else if(tree.IsHasCommonTasks || tree.IsHasChildren){
        console.log(4);
        // let para4 = {
        //   taskId: mytaskid,
        // };
        // var pid = tree.TaskId?tree.TaskId:tree.Id;
        var pid = tree.Id?tree.Id:tree.TaskId;
        QueryChildTasksByParentId(para).then((res) => {
          // resolve(res.data.response)
          var myarr = new Array();
          var number = 0;
          res.data.response.forEach(element => {
            element.Number = ++number;
            element = this.rowformat(element,pid);
            myarr.push(element)
          });
          resolve(myarr);
          // console.log(myarr)
        });
      }




































      
      // QueryChildTasksByParentId(para).then((res) => {
      //   var data = [];
      //   var d = res.data.response;
      //   var number = 0;
      //   if(tree.PersonOfDuty == '')
      //   {
      //     if(this.myvalue.length>0)
      //     {
      //       console.log('a')
      //       d.forEach(element => {
      //         element = this.rowformat(element,mytaskid);
      //         if(this.myvalue.indexOf(element.CreatedId)>=0)
      //         {
      //           element.Number = ++number;
      //           data.push(element);
      //         }else if(this.myvalue.indexOf(element.PersonOfDutyCode)>=0){
      //           element.Number = ++number;
      //           data.push(element);
      //         }
      //       });
      //       resolve(data);
      //     }else{
      //       console.log('b')
      //       d.forEach(element => {
      //           var result = this.departusers.filter(item => {
      //             return item.id==element.PersonOfDutyCode
      //           });
      //           if(result.length>0)
      //           {
      //             element.Number = ++number;
      //             element = this.rowformat(element,mytaskid);
      //             data.push(element);
      //           }
      //       });
      //       resolve(data);
      //     }
      //   }else{
      //       console.log('c')
      //       d.forEach(element => {
      //         element.Number = ++number;
      //         element = this.rowformat(element,mytaskid);
      //         data.push(element);
      //       });
      //       // data = d;
      //       resolve(data);
      //   }
      // });


    },
    load2 (tree, treeNode, resolve) {
      var notaskType = tree.NoTask;
      var mytaskid = tree.TaskId;
      let para = {
        taskId: tree.TaskId,
      };
      if (tree.PersonOfDuty == '') {
        para.notaskType = notaskType;
      }
      else {
        para.notaskType = 0;
      }
      QueryChildTasksByParentId(para).then((res) => {
        var data = [];
        var d = res.data.response;
        var number = 0;
        if(tree.PersonOfDuty == '')
        {
          if(this.myvalue.length>0)
          {
            console.log('a')
            d.forEach(element => {
              element = this.rowformat(element,mytaskid);
              if(this.myvalue.indexOf(element.CreatedId)>=0)
              {
                element.Number = ++number;
                data.push(element);
              }else if(this.myvalue.indexOf(element.PersonOfDutyCode)>=0){
                element.Number = ++number;
                data.push(element);
              }
            });
            resolve(data);
          }else{
            console.log('b')
            d.forEach(element => {
                var result = this.departusers.filter(item => {
                  return item.id==element.PersonOfDutyCode
                });
                if(result.length>0)
                {
                  element.Number = ++number;
                  element = this.rowformat(element,mytaskid);
                  data.push(element);
                }
            });
            resolve(data);
          }
        }else{
            console.log('c')
            d.forEach(element => {
              element.Number = ++number;
              element = this.rowformat(element,mytaskid);
              data.push(element);
            });
            // data = d;
            resolve(data);
        }
      });
    },
    formatPlanComplateTime: function (row, colume) {
      return (!row.PlanComplateTime || row.PlanComplateTime == '') ? '' : util.formatDate.format(new Date(row.PlanComplateTime), 'yyyy-MM-dd');
    },
    formatCreatedOn: function (row, colume) {
      return (!row.CreatedOn || row.CreatedOn == '') ? '' : util.formatDate.format(new Date(row.CreatedOn), 'yyyy-MM-dd hh:mm');
    },
    formatCommonTaskClassifyText: function (row, colume) {
      if (row.TaskClassify == 1) {
        return row.CommonTaskClassifyText;
        // return "日常工作";
      } else {
        if (row.TaskClassify == 2) {
          return "跨部门协调";
        }
        else if (row.TaskClassify == 3) {
          return "例会项目";
        } else if (row.TaskClassify == 4) {
          return "年度计划";
        } else if (row.TaskClassify == 5) {
          return "专项会议";
        }
        else {
          return "其他";
        }
      }
    },
    formatProgress: function (row, col) {
      if (row.PersonOfDuty != '') {
        return row.TaskProgressValue + "%";
      }
      return '';
    },
    formatSource: function (row, column) {
      return row.ParentId != null ? '被指派' : row.ParentId == null ? '新建' : '未知';
    },
    formatCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.dateformat(new Date(row.CreateTime));
    },
    formatTipCreateTime: function (row, column) {
      return (!row.CreateTime || row.CreateTime == '') ? '' : util.formatDate.format(new Date(row.CreateTime), 'yyyy-MM-dd hh:mm');
    },
    formatLastInputProcessTime: function(row,column) {
        if(row.LastProgressTime=="0001-01-01 00:00:00")
        {
            return '未维护'
        }else{
            return (!row.LastProgressTime || row.LastProgressTime == '') ? '' : util.formatDate.dateformat(new Date(row.LastProgressTime))+'维护';
        }
    },
    formatTipLastInputProcessTime: function (row, column) {
        if(row.LastProgressTime=="0001-01-01 00:00:00")
        {
            return '未维护'
        }else{
            return (!row.LastProgressTime || row.LastProgressTime == '') ? '' : util.formatDate.format(new Date(row.LastProgressTime),'yyyy-MM-dd hh:mm');
        }
    },
    //翻页处理
    handleCurrentChange (val) {
      this.page = val;
      this.getManagerChecks();
      // this.page = 1;
    },
    handleExport()
    {
      this.exportExcel();
    },
    //定义导出Excel表格事件
    exportExcel() {
        /* 从表生成工作簿对象 */
        var wb = XLSX.utils.table_to_book(document.querySelector("#mytable"));
        this.setExlStyle(wb['Sheets']['Sheet1']);
        let wb_out = xlsxstyle.write(wb,{type:'buffer'});
        /* 获取二进制字符串作为输出 */
        var wbout = XLSX.write(wb, {
            bookType: "xlsx",
            bookSST: true,
            type: "array"
        });
        var filename= util.formatDate.format(new Date(),'yyyy-MM-dd hh:mm');
        try {
            FileSaver.saveAs(
            //Blob 对象表示一个不可变、原始数据的类文件对象。
            //Blob 表示的不一定是JavaScript原生格式的数据。
            //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
            //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
            new Blob([wb_out], { type: "application/octet-stream" }),
            //设置导出文件名称
            filename+"详细任务查询后导出.xlsx"
            );
        } catch (e) {
            if (typeof console !== "undefined") console.log(e, wbout);
        }
        return wbout;
    },
    setExlStyle(data) {
      let borderAll = {  //单元格外侧框线
        top: {
          style: 'thin',
        },
        bottom: {
          style: 'thin'
        },
        left: {
          style: 'thin'
        },
        right: {
          style: 'thin'
        }
      };
      data['!cols'] = [];
      for (let key in data) {
        // console.log(key)
        if (data[key] instanceof Object) {
          var row = key.replace(/[^0-9]/ig, '')
          data[key].s = {
            border: borderAll,
            alignment: {
              horizontal: 'center',   //水平居中对齐
              vertical:'center',
              wrapText: 1
            },
            fill: row==1?{fgColor: { rgb: '4FADC2' }}:{fgColor: { indexed: 64 }},
            font:{
              name:'微软雅黑',
              sz:11,
            },
            bold:true,
            numFmt: 0
          }
          data['!cols'].push({wpx: 115});
        }
      }
      return data;
    },
    addRangeBorder (range, ws) {
      let cols = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
      range.forEach(item => {
        console.log(item)
        let style = {
          s: {
            border: {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            }
          }
        }
        // 处理合并行
        for (let i = item.s.c; i <= item.e.c; i++) {
          ws[`${cols[i]}${Number(item.e.r) + 1}`] = ws[`${cols[i]}${Number(item.e.r) + 1}`] || style
          // 处理合并列
          for (let k = item.s.r + 2; k <= item.e.r + 1; k++) {
            ws[cols[i] + k] = ws[cols[k] + item.e.r] || style
          }
        }
      })
      return ws;
    },
    getManagerChecks () {
      //时间转化
      let begintime = "";  //开始时间
      let endtime = "";    //结束时间
      if (this.madedate != null) {
        switch (this.madedate.length) {
          case 1:
            begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
            break;
          case 2:
            begintime += util.formatDate.format(new Date(this.madedate[0]), 'yyyy-MM-dd');
            endtime += util.formatDate.format(new Date(this.madedate[1]), 'yyyy-MM-dd');
            break;
          default:
            break;
        }
      }
      let planbegintime = "";  //开始时间
      let planendtime = "";    //结束时间
      if (this.plandate != null) {
        switch (this.plandate.length) {
          case 1:
            planbegintime += util.formatDate.format(new Date(this.plandate[0]), 'yyyy-MM-dd');
            break;
          case 2:
            planbegintime += util.formatDate.format(new Date(this.plandate[0]), 'yyyy-MM-dd');
            planendtime += util.formatDate.format(new Date(this.plandate[1]), 'yyyy-MM-dd');
            break;
          default:
            break;
        }
      }
      var peoples=this.myvalue;
      
      var userstr="";
      if(peoples.length>0)
      {
        for(var a=0;a<peoples.length;a++)
        {
          userstr+=peoples[a];
          userstr+=',';
        }
      }
      else if(this.departusers.length>0)
      {
        for(var a=0;a<this.departusers.length;a++)
        {
          var u = this.departusers[a];
          userstr+=this.departusers[a].id;
          userstr+=',';
        }
        // userstr=" ";
      }
      else{
        this.$message({
          message: '请选择想要查看的部门或人员',
          type: 'warning'
        });
        return;
      }



      let para = {
        departmentId:this.checkdepartid,
        includeChildren:this.radioOptions,
        // includeChildren:true,
        // userCode: userstr,
        workItem: this.workItem,
        taskClassify: this.taskClassify.length>1?this.taskClassify[1]:this.taskClassify[0],
        taskStatus: this.taskStatus,
        begin: begintime,
        end: endtime,
        planbegin: planbegintime,
        planend: planendtime,
        yearplanId: this.YearPlanTaskId,
        progressWhere: this.progressWhere,
        progressValue: this.progressValue,
        pageIndex: this.page,
        pageSize: this.pageSize
      };
      this.$message({
        message: '开始查询...',
        type: 'success'
      });
      QueryPageTasksByWhere20220601(para,userstr).then((res) => {
        if(res.status==413 || res.status==431)
        {
          this.$message({
            message: '由于该部门组织下人员较多，本次查询失败，请缩小查阅范围',
            type: 'warning',
            duration:5000
          });
        }else{
          this.total = res.data.response.pageCount;
          this.totaldata = res.data.response.dataCount;
          var arr=new Array();
          var number = (this.page-1)*this.pageSize;
          res.data.response.data.forEach(element => {
            // console.log(element);
            element.Number=++number;
            if(element.IsHasChildrenYearplan || element.IsHasNormalTasks || element.IsHasCommonTasks || element.IsHasChildren)
            {
              element.IsHasChildren=true;
            }else{
              element.IsHasChildren=false;
            }
            element.MarginLeft=3;
            element.Level = 1;
            // this.mapLevel.set(element.TaskId,1);
            if(element.NoTask)
            {
              console.log(element.NoTask);
              this.mapLevel.set(element.Id,1);
            }else{
              this.mapLevel.set(element.TaskId,1);
            }
            arr.push(element)
          });
          this.users = arr;
          // this.users = res.data.response.data;
          this.$message({
            message: '查询完成',
            type: 'success'
          });
        }
      }).catch(err=>{
        // console.log(err);
      });
    },
    checkclose () {
      this.checkVisible = false;
      this.pValue1 = 0;
    },
    //双击查看详细信息
    checkInfo (row) {
      if (row.NoTask > 0) {
        return;
      }
      this.checkVisible = true;
      this.checkForm = row;
      return;
      if (row.TaskClassify == 1) {
        // this.CommonTaskClassifyText = row.CommonTaskClassifyText;
        this.CommonTaskClassifyText = "日常工作";
        this.showorganize = false;
        this.showyearplan = false;
        this.showregularproject = false;
        this.showprofessionmeetong = false;
        if (row.PersonOfDuty != row.CreatedBy) {
          this.showAssign = true;
        } else {
          this.showAssign = false;
        }
        this.createPerson = true;
      } else {
        if (row.TaskClassify == 2) {
          this.CommonTaskClassifyText = "跨部门协调";
          this.showorganize = true;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = false;
        }
        else if (row.TaskClassify == 3) {
          this.CommonTaskClassifyText = "例会项目";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = true;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = true;
        } else if (row.TaskClassify == 4) {
          this.CommonTaskClassifyText = "年度计划";
          this.showorganize = false;
          this.showyearplan = true;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = false;
        } else if (row.TaskClassify == 5) {
          this.CommonTaskClassifyText = "专项会议";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = true;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = true;
        }
        else {
          this.CommonTaskClassifyText = "其他";
          this.showorganize = false;
          this.showyearplan = false;
          this.showregularproject = false;
          this.showprofessionmeetong = false;
          if (row.PersonOfDuty != row.CreatedBy) {
            this.showAssign = true;
          } else {
            this.showAssign = false;
          }
          this.createPerson = false;
        }
      }
      QueryTasksById({ Id: row.Id, taskClassify: row.TaskClassify }).then((res) => {
        this.checkForm = res.data.response;
        this.checkForm.Files = [];
        if (res.data.response.FileName != null) {
          var arr = res.data.response.FileName.split(",");
          var arr2 = res.data.response.FileAddress.split(",");
          var files = [];
          for (var a = 0; a < arr.length; a++) {
            var f = {};
            f.FileName = arr[a];
            f.FileAddress = arr2[a];
            files.push(f);
          }
          this.checkForm.Files = files;
        }
      })
      this.pValue1 = row.TaskProgressValue;
      let para = { taskId: row.Id };
      QueryTaskProgressByTaskId(para).then((res) => {
        var data = res.data.response;
        let formatData = new Array();
        for (var a = 0; a < data.length; a++) {
          var element = data[a];
          element.Files = [];
          if (element.FileName != null) {
            if(element.FileName != '')
            {
              var arr = element.FileName.split(",");
              var arr2 = element.FileAddress.split(",");
              var files = [];
              for (var d = 0; d < arr.length; d++) {
                var f = {};
                f.FileName = arr[d];
                f.FileAddress = arr2[d];
                files.push(f);
              }
              element.Files = files;
            }
          }
          formatData.push(element);
        }
        this.tasks = formatData;
        this.$nextTick(function () {
          var div = document.getElementsByClassName("tasksdiv");
          if (div && 0 < div.length) {
            div[0].scrollTop = div[0].scrollHeight;
          }
        })
      });
    },
    cascaderchange (node) {
      let self=this;
      if(this.$refs.department.getCheckedNodes().length>0)
      {
        let pathvalue = this.$refs.department.getCheckedNodes()[0];
        this.checkdepartid = pathvalue.value;
        this.getDepartUsers(pathvalue.value);
        setTimeout(() => {
          let para2 = {
            deptId: self.checkdepartid,
            hasJob:0,
            isAllNextDept:false,
            includeChildren: self.radioOptions,
            onlyPrincipal:true,
            pageNum: 1,
            pageSize: 5000
          }
          GetDepartmentPostUserList(para2).then(res => {
            var data = res.data.data.list;
            self.list = data.map(item => {
              return { value: item.id, label: item.name+item.username };
            });
            self.myoptions = self.list;
          });
        }, 200);
      }else{
        this.users=[];
        this.total = 0;
        this.totaldata = 0;
      }
    },

    handleChooseOKDpt()
    {
      var nodes = this.$refs.tree1.getCheckedNodes();
      // console.log(nodes);
      this.chooseDptVisable=false;
      this.checkdepartid = nodes[0].id;
      this.btntext=nodes[0].deptName;
      // this.departid = nodes[0].id;
      // this.cascaderchange();
      this.getDepartUsers(this.checkdepartid);

    },
    handleChooseDpt()
    {
      this.alldepartmentdata=[];
      this.chooseDptVisable=true;
    },
    handleChooseGroup(){
      this.chooseGroupPersonVisiable = true;
    },
    onDptOpened()
    {
      let self=this;
      var user = JSON.parse(window.localStorage.user);
      var userid = user.sub;
      QuerySearchQuanxianListByUsercode({ usercode: userid }).then(res => {
        // console.log(res);
        var dataArr=res.data.response;
        var nodes=new Array();
        var n=0;
        for(var d=0;d<dataArr.length;d++)
        {
          if(dataArr[d].Type==99 || dataArr[d].Type==1)
          {
            var dptIds=dataArr[d].DepartmentCode;
            var dptNames=dataArr[d].DepartmentName;
            var dptIdArr=dptIds.split(',');
            var dptNameArr=dptNames.split(',');
            for(var a=0;a<dptIdArr.length;a++)
            {
              if(dptIdArr[a].length>0)
              {
                GetDepartmentTree({deptId:dptIdArr[a]}).then((res) => {
                  if (res.data.code==200) {
                    for(var d=0;d<res.data.data.dept.length;d++)
                    {
                      self.alldepartmentdata.push(res.data.data.dept[d]);
                    }
                  }
                });
              }
            }
            n++;
          }
        }
        if(n==0)
        {
          GetUserTopDepartmentList({ userId: userid }).then(res => {
            res.data.data.forEach(element => {
                GetDepartmentTree({deptId:element.deptId}).then((res) => {
                  if (res.data.code==200) {
                    for(var d=0;d<res.data.data.dept.length;d++)
                    {
                      self.alldepartmentdata.push(res.data.data.dept[d]);
                    }
                  }
                });
            });
          });
        }
      })
    },
    handleCurrentChangeDepart (val) {
      //console.log(val)
      this.Departpage = val;
      this.getDepartUsers(this.checkdepartid);
      this.Departpage = 1;
    },
    //获取部门人员
    getDepartUsers (node) {
      var includeChildrenValue=false;
      if(this.radioOptions)
      {
        includeChildrenValue=true;
      }
      let para = {
        deptId: node,
        hasJob:0,
        // isAllNextDept:false,
        isAllNextDept:true,
        includeChildren:includeChildrenValue,
        pageNum: this.Departpage,
        pageSize: this.DepartpageSize
      }
      // console.log(para)
      GetDepartmentPostUserList(para).then(res => {
        var data = res.data.data.list;
        // console.log(res);
        let newdata = this.unique(data);
        // console.log(newdata)
        this.departusers = newdata;
        this.Departtotal = res.data.data.pages;
        this.Departtotaldata = res.data.data.total;
      });
    },
    tableRowClassName ({ row, rowIndex }) {
      // if (rowIndex % 2 !== 0) {
      //   return 'warning-row';
      // }
      return '';
    },
    unique (arr) {
      const res = new Map();
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1));
    },
    handleCheck(val,node)
    {
      // console.log(val);
      // console.log(node);
      let self=this;
      self.myoptions=[];
      self.departusers=[];
      for(var a=0;a<node.checkedNodes.length;a++)
      {
        var item = node.checkedNodes[a];
        if(
          item.label == '格瑞德集团' || 
          item.label == '董事会' || 
          item.label == '集团公司'
        ){
          this.$message({
            message: '因查阅数据较大，您不能直接选择顶级部门进行查询',
            type: 'info'
          });
          for(var n=0;n<node.checkedNodes.length;n++)
          {
            this.$refs.tree1.setChecked(node.checkedNodes[n],false);
          }
          // this.$refs.tree1.setCheckedKeys([]);
          // this.$refs.tree1.setCurrentKey(null);

          break;
        }else{
          // console.log(a);
          var dptid = item.id;
          // console.log(dptid);
          // console.log(dptid);
          self.getUser(dptid);
        }
      }
    },
    getUser(dptid)
    {
      let self=this;
      // console.log(dptid)
      let para2 = {
        deptId: dptid,
        hasJob:0,
        isAllNextDept: self.radioOptions,
        onlyPrincipal:true,
        isMainJob:true,
        pageNum: 1,
        pageSize: 5000
      }
      // console.log(para2);
      try
      {
        GetDepartmentPostUserList(para2).then(res => {
          // console.log(res);
          try
          {
            // console.log(res.data.data.list)
            let data = res.data.data.list;
            var arr = new Array();
            for(var a=0;a<data.length;a++)
            {
              // console.log(a);

              let item=data[a];
              // console.log(item)
              // console.log(item.id);
              let obj = { value: item.id, label: item.name+item.username };
              // console.log(obj);
              self.departusers.push({ id:item.id,name:item.name });
              // arr.push(obj);
              self.myoptions.push(obj);
            }
            // console.log(arr);
            // self.myoptions = arr;
            // self.myoptions.push(obj);
          }catch(erra)
          {
            
          }
          // self.list = data.map(item => {
          //   // return { value: item.id, label: item.name+item.username };
          //   var obj = { value: item.id, label: item.name+item.username };
          //   self.myoptions.push(obj);
          // });
          // self.myoptions = self.list;
        });
      }catch(err)
      {

      }
    },
    loadDptTree()
    {
      let self=this;
      var user = JSON.parse(window.localStorage.user);
      var userid = user.sub;
      QuerySearchQuanxianListByUsercode({ usercode: userid }).then(res => {
        // console.log(res);
        var dataArr=res.data.response;
        var nodes=new Array();
        var n=0;
        for(var d=0;d<dataArr.length;d++)
        {
          if(dataArr[d].Type==99 || dataArr[d].Type==1)
          {
            var dptIds=dataArr[d].DepartmentCode;
            var dptNames=dataArr[d].DepartmentName;
            var dptIdArr=dptIds.split(',');
            var dptNameArr=dptNames.split(',');
            for(var a=0;a<dptIdArr.length;a++)
            {
              if(dptIdArr[a].length>0)
              {
                // console.log(dptIdArr[a]);

                
                GetDepartmentTree({deptId:dptIdArr[a]}).then((res) => {
                  if (res.data.code==200) {
                    // console.log(res.data.data.dept)
                    // self.alldepartmentdata=(res.data.data.dept);
                    for(var d=0;d<res.data.data.dept.length;d++)
                    {
                      self.alldepartmentdata.push(res.data.data.dept[d]);
                    }
                    // self.alldepartmentdata.push(res.data.data.dept);
                    // console.log(self.alldepartmentdata)
                    // this.alldepartmentdata = res.data.data.dept;
                    // console.log(res.data.response.children);
                  }
                });
              }
            }
            n++;
          }
        }
        if(n==0)
        {
          GetUserTopDepartmentList({ userId: userid }).then(res => {
            res.data.data.forEach(element => {
                GetDepartmentTree({deptId:element.deptId}).then((res) => {
                  if (res.data.code==200) {
                    for(var d=0;d<res.data.data.dept.length;d++)
                    {
                      self.alldepartmentdata.push(res.data.data.dept[d]);
                    }
                  }
                });
            });
          });
        }

      });
    }
  },
  watch: {
    filterDptText(val) {
      this.$refs.tree1.filter(val);
    }
  },
  mounted () {
    // let pathvalue = this.$refs.department;

    // GetDepartmentTree().then((res) => {
    //   if (res.data.code==200) {
    //     this.alldepartmentdata = res.data.data.dept;
    //     // console.log(res.data.response.children);
    //   }
    // });
    this.loadDptTree();




  }
}
</script>
<style scoped>
.orgtree {
  overflow-y: auto;
  overflow-x: scroll;
  height: calc(100vh - 280px);
  /* width: 200px; */
  border: 0px solid blue;
  padding-top: 65px;
}
.orgbtndivtree {
  padding-left: 20px;
  padding-right: 20px;
}
/deep/.el-table__body tr.current-row > td {
  background: #fabe64 !important;
}
/deep/input:disabled,
/deep/textarea:disabled {
  opacity: 1;
  -webkit-text-fill-color: rgb(12, 12, 12);
}

/deep/.el-table .warning-row {
  background: #f0f8ff;
}
/deep/.el-button--goon {
  color: #fff;
  background-color: #20b2aa;
  border-color: #20b2aa;
}
/deep/.el-button--goon:hover {
  background: #48d1cc;
  border-color: #48d1cc;
  color: #fff;
}
/deep/.el-button--goon:focus {
  background: #20b2aa;
  border-color: #20b2aa;
  color: #fff;
}
/deep/span.el-range-separator {
  width: 20px;
}

.managercheckdiv,
.managercheckcard {
  height: calc(100vh - 220px);
}
.tasksdiv {
  max-height: calc(100vh - 350px);
  overflow: auto;
  padding-left: 2px;
}
.active {
  background-color: #00000014;
}
.eldropdownitem,
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
</style>